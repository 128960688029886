import {
  Center,
  Heading,
  Input,
  Button,
  FormControl,
  Image,
  Stack,
  Text,
} from "native-base";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import bg from "../assets/background.png";
import logo from "../assets/logo.svg";
import FullHeight from "react-full-height";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SetPinPage() {
  //   form data
  const [policyNumber, setPolicyNumber] = useState(
    useHistory().location.state || ""
  );
  const [oldCode, setOldCode] = useState("");
  const [newCode, setNewCode] = useState("");
  const [verifyCode, setVerifyCode] = useState("");

  // loading
  const [isLoading, setIsLoading] = useState(false);

  // history to handle navigation
  const history = useHistory();

  // input type
  const [oldType, setOldtype] = useState("password");
  const [newType, setNewType] = useState("password");
  const [verifyType, setVerifyType] = useState("password");

  // console.log(oldCode);

  // validate fields

  const validateFields = (params) => {
    if (
      policyNumber === "" ||
      oldCode === "" ||
      newCode === "" ||
      verifyCode === ""
    ) {
      toast.warn("Fields cannot be empty!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else if (newCode.length < 5 || verifyCode.length < 5) {
      toast.warn(
        "Your secret pin should not be shorter than 5 digits or letters!",
        {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (newCode !== verifyCode) {
      toast.warn(
        "Your secret pins do not match, Please make sure you retype the same secret pin",
        {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (oldCode === newCode) {
      toast.warn("You cannot reuse the same code as a new!", {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setIsLoading(true);
    const pathParams = new URLSearchParams({
      policynum: policyNumber,
      newCode: newCode,
      oldCode: oldCode,
    });

    console.log(process.env.REACT_APP_CHANGE_PIN_URL + pathParams);

    // passed validation: fetch get code
    fetch(process.env.REACT_APP_CHANGE_PIN_URL + pathParams)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          let successMessage = data.message;
          Promise.resolve()
            .then(setIsLoading(false))
            .then(alert(successMessage))
            .finally(
              history.push({
                pathname: "/check-statement",
              })
            );
        } else {
          let errorMessage = data.message;
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });

    return;
  };

  return (
    <FullHeight
      canExceed
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 15,
      }}
    >
      <BackgroundImage />
      <ToastContainer />
      <Center
        w={["95%", "90%", "80%", "60%"]}
        bg="white.500"
        rounded={5}
        shadow={5}
        py={10}
      >
        <Image
          source={logo}
          alt="logo"
          accessibilityLabel="logo"
          w="40vh"
          h="7vh"
          resizeMode="contain"
        />
        <Heading mt={5} fontSize="2xl">
          CHANGE SECRET CODE
        </Heading>
        <Text>
          Kindly set a new secret code/pin that would be used to verify your
          statement request
        </Text>

        <Stack w={["90%", "80%", "70%", "50%"]} my={5} space={4}>
          <FormControl isRequired>
            <FormControl.Label>Policy Number</FormControl.Label>
            <Input
              variant="filled"
              rounded="lg"
              size="lg"
              value={policyNumber}
              onChangeText={(value) => setPolicyNumber(value)}
            />
          </FormControl>

          {/* old code */}
          <FormControl isRequired>
            <FormControl.Label>Registration Code</FormControl.Label>
            <FormControl.HelperText
              _text={{
                fontStyle: "italic",
                color: "black.300",
                fontSize: "xs",
                mt: -3,
                mb: 1,
              }}
            >
              Enter the registration code sent your phone or email
            </FormControl.HelperText>
            <Input
              variant="filled"
              rounded="lg"
              size="lg"
              type={oldType}
              value={oldCode}
              onChangeText={(value) => setOldCode(value)}
              InputRightElement={
                oldType === "password" ? (
                  <Button
                    onPress={() => {
                      setOldtype("text");
                    }}
                  >
                    <FiEyeOff color="white" />
                  </Button>
                ) : (
                  <Button
                    onPress={() => {
                      setOldtype("password");
                    }}
                  >
                    <FiEye color="white" />
                  </Button>
                )
              }
            />
          </FormControl>
          {/* old code ends */}

          {/* new code  */}
          <FormControl isRequired>
            <FormControl.Label>Enter New Secret PIN</FormControl.Label>
            <FormControl.HelperText
              _text={{
                fontStyle: "italic",
                color: "black.300",
                fontSize: "xs",
                mt: -3,
                mb: 1,
              }}
            >
              Create a new 6-digit Secret PIN to complete your registration
              process
            </FormControl.HelperText>
            <Input
              variant="filled"
              rounded="lg"
              size="lg"
              type={newType}
              value={newCode}
              onChangeText={(value) => setNewCode(value)}
              InputRightElement={
                newType === "password" ? (
                  <Button
                    onPress={() => {
                      setNewType("text");
                    }}
                  >
                    <FiEyeOff color="white" />
                  </Button>
                ) : (
                  <Button
                    onPress={() => {
                      setNewType("password");
                    }}
                  >
                    <FiEye color="white" />
                  </Button>
                )
              }
            />
          </FormControl>
          {/* new code ends */}

          {/* verify code starts  */}
          <FormControl isRequired>
            <FormControl.Label>Retype New Secret PIN</FormControl.Label>
            <Input
              variant="filled"
              rounded="lg"
              size="lg"
              type={verifyType}
              value={verifyCode}
              onChangeText={(value) => setVerifyCode(value)}
              InputRightElement={
                verifyType === "password" ? (
                  <Button
                    onPress={() => {
                      setVerifyType("text");
                    }}
                  >
                    <FiEyeOff color="white" />
                  </Button>
                ) : (
                  <Button
                    onPress={() => {
                      setVerifyType("password");
                    }}
                  >
                    <FiEye color="white" />
                  </Button>
                )
              }
            />
          </FormControl>
          {/* verify code ends  */}

          <Button
            isLoading={isLoading}
            onPress={() =>
              validateFields({
                policyNumber: policyNumber,
                newCode: newCode,
                oldCode: oldCode,
              })
            }
          >
            SET NEW CODE
          </Button>

          <Text textAlign="center">
            Already registered?{" "}
            <Link to="/check-statement">Check Statement</Link>
          </Text>
        </Stack>
      </Center>
    </FullHeight>
  );
}

export default SetPinPage;

const BackgroundImage = styled.div`
  background: url("${bg}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

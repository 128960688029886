import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import RegisterPage from "../views/RegisterPage";
import CheckStatementPage from "../views/CheckStatementPage";
import SetPinPage from "../views/SetPinPage"

export default function RootLayout() {
  return (
    <Switch>
      <Route path="/register" component={RegisterPage} />
      <Route path="/check-statement" component={CheckStatementPage} />
      <Route path="/set-pin" component={SetPinPage} />
      <Redirect to="/register" />
    </Switch>
  );
}

import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Center,
  Input,
  Button,
  FormControl,
  Image,
  Stack,
  Heading,
  Text,
  Box,
  Link as NbLink
} from "native-base";

import { FiUser, FiMail, FiPhone, } from "react-icons/fi";

import styled from "styled-components";
import bg from "../assets/background.png";
import logo from "../assets/logo.svg";
import FullHeight from "react-full-height";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import * as moment from "moment";

const theme = createTheme({
  palette: {
    background: {
      main: "#eb1c24",
    },
    primary: {
      main: "#eb1c24",
    },
    secondary: {
      main: "#76c9d2",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#9D399E",
    },
  },
});

function RegisterScreen() {
  // form data
  const [policyNumber, setPolicyNumber] = useState("");
  const [dob, setDob] = useState(moment());
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // loading
  const [isLoading, setIsLoading] = useState(false);

  // history to handle navigation
  const history = useHistory();

  //  validate
  const validateFields = () => {
    if (policyNumber === "" || email === "" || phone === "" || dob === "") {
      toast.warn("Fields cannot be empty!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    } else if (
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === null
    ) {
      toast.warn("Please enter a valid email!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    } else if (phone.match(/^[0-9]{10}$/) === null) {
      toast.warn("Please input a correct 10 digit phone number!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const pathParams = new URLSearchParams({
      policynum: policyNumber,
      dob: dob.format("YYYY-MM-DD"),
      email: email,
      phoneNum: phone,
    });

    console.log(process.env.REACT_APP_REGISTER_URL + pathParams);

    setIsLoading(true);
    fetch(process.env.REACT_APP_REGISTER_URL + pathParams)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          let successMessage = data.message;
          Promise.resolve()
            .then(setIsLoading(false))
            .then(alert(successMessage))
            .finally(
              history.push({
                pathname: "/set-pin",
                state: policyNumber,
              })
            );
        } else {
          let errorMessage = data.message;
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false);
      });

    return;
  };

  return (
    <FullHeight
      canExceed
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
      }}
    >
      <BackgroundImage />
      <ToastContainer />

      <Center
        w={["95%", "90%", "80%", "60%"]}
        bg="white.500"
        rounded={5}
        shadow={5}
        py={10}
      >
        <Image
          source={logo}
          alt="logo"
          accessibilityLabel="logo"
          w="40vh"
          h="7vh"
          resizeMode="contain"
        />
        <Heading mt={5} fontSize="2xl">
          REGISTER TO CHECK YOUR STATEMENT
        </Heading>
        <Text textAlign="center" mx={2}>
          Register to receive a code on your phone or email to validate your
          statement check
        </Text>

        <Stack w={["90%", "80%", "70%", "50%"]} my={5} space={4}>
          <FormControl isRequired>
            <FormControl.Label>Policy Number</FormControl.Label>
            <Input
              variant="filled"
              rounded="lg"
              size="lg"
              placeholder="DL1P0012345"
              placeholderTextColor="black.400"
              InputRightElement={
                <Box m={4} mx={7}>
                  <FiUser size={20} />
                </Box>
              }
              value={policyNumber}
              onChangeText={(value) => setPolicyNumber(value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormControl.Label>Date of Birth</FormControl.Label>
            <ThemeProvider theme={theme}>
              <KeyboardDatePicker
                value={dob}
                onChange={setDob}
                inputVariant="filled"
                format="YYYY-MM-DD"
                style={{ border: "0px solid" }}
              />
            </ThemeProvider>
          </FormControl>

          <FormControl isRequired>
            <FormControl.Label>Email</FormControl.Label>
            <Input
              variant="filled"
              rounded="lg"
              size="lg"
              placeholder="user@domain.com"
              placeholderTextColor="black.400"
              InputRightElement={
                <Box m={4} mx={7}>
                  <FiMail size={20} />
                </Box>
              }
              value={email}
              onChangeText={(value) => setEmail(value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormControl.Label>Mobile Number</FormControl.Label>
            <Input
              variant="filled"
              rounded="lg"
              size="lg"
              placeholder="0151231234"
              placeholderTextColor="black.400"
              InputRightElement={
                <Box m={4} mx={7}>
                  <FiPhone size={20} />
                </Box>
              }
              value={phone}
              onChangeText={(value) => setPhone(value)}
            />
          </FormControl>

          <Box>
            <Button
              my={5}
              isLoading={isLoading}
              onPress={() => {
                validateFields();
              }}
            >
              <Text fontSize="lg" color="white.100" mx={2}>
                REGISTER
              </Text>
            </Button>
            <Text textAlign="center">
              Already registered?{" "}
              <Link to="/check-statement">Check Statement</Link>
            </Text>

            <Center>
              <Text
                mt={1}
                textAlign="center"
                fontSize="md"
                underline
                onPress={() =>
                  window.open(
                    "https://www.donewelllife.com.gh/subscribe/",
                    "_self"
                  )
                }
              >
                Send statement via email
              </Text>
            </Center>
          </Box>
        </Stack>
      </Center>
    </FullHeight>
  );
}

export default RegisterScreen;

const BackgroundImage = styled.div`
  background: url("${bg}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

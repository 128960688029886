// import "./App.css";
import { createBrowserHistory } from "history";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";

const hist = createBrowserHistory();

function App() {
  return (
    <Router basename={"/"} history={hist}>
      <RootLayout />
    </Router>
  );
}

export default App;

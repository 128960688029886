export const months = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMonth = (value) => {
  return parseInt(value.slice(-2));
};

export const getYear = (value) => {
  return value.substring(0, 4);
};
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Center,
  Input,
  Button,
  FormControl,
  Image,
  Stack,
  Heading,
  Text,
  FlatList,
  HStack,
  Box,
} from "native-base";
import styled from "styled-components";
import bg from "../assets/background.png";
import logo from "../assets/logo.svg";
import FullHeight from "react-full-height";

import { FiShield, FiLock, FiEye, FiEyeOff } from "react-icons/fi";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// date imports
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import * as moment from "moment";

// components
import StatementDetails from "../components/StatementDetails";

// utils

//  date theme
const theme = createTheme({
  palette: {
    background: {
      main: "#eb1c24",
    },
    primary: {
      main: "#eb1c24",
    },
    secondary: {
      main: "#76c9d2",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#9D399E",
    },
  },
});

// month objects

function CheckStatementPage() {
  // data state
  const [data, setData] = useState([]);

  // form data
  const [policyNumber, setPolicyNumber] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const [dateFrom, setDateFrom] = useState(moment());
  const [dateTo, setDateTo] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);

  // password eye
  const [codeType, setCodeType] = useState("password");

  //validate
  const validateFields = () => {
    if (
      policyNumber === "" ||
      secretCode === "" ||
      dateFrom === "" ||
      dateTo === ""
    ) {
      toast.warn("Fields cannot be empty!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }
    setIsLoading(true);

    // passed validation: fetch get code

    const pathParams = new URLSearchParams({
      policyNumber: policyNumber,
      code: secretCode,
      startDate: dateFrom.format("YYYY-MM-DD"),
      endDate: dateTo.format("YYYY-MM-DD"),
    });

    fetch(process.env.REACT_APP_GET_STATEMENT_URL + pathParams)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // console.log(data.data);
          setData(data.data);
          setIsLoading(false);
        } else {
          let errorMessage = data.message;
          toast.error(errorMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
    return;
  };

  return (
    <FullHeight
      canExceed
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 15,
      }}
    >
      <BackgroundImage />
      <ToastContainer />

      <Center
        w={["95%", "90%", "80%", "75%"]}
        bg="white.500"
        rounded={5}
        shadow={5}
        py={10}
      >
        <Image
          source={logo}
          alt="logo"
          accessibilityLabel="logo"
          w="40vh"
          h="7vh"
          resizeMode="contain"
        />
        <Heading mt={5} fontSize="2xl">
          STATEMENT REQUEST
        </Heading>
        <Text mx={2} textAlign="center">
          Use your policy number, secret PIN, start and end date to check your
          statement
        </Text>

        {/* form starts  */}
        <Stack w={["90%", "80%", "70%", "50%"]} mt={5} space={4}>
          <FormControl>
            <FormControl.Label>Policy Number</FormControl.Label>
            <Input
              variant="filled"
              rounded="lg"
              size="lg"
              InputLeftElement={
                <Center p={2.5} bg="primary.100">
                  <FiShield size={20} color="red" />
                </Center>
              }
              placeholder="DL1P0012345"
              value={policyNumber}
              onChangeText={(value) => setPolicyNumber(value)}
            />
          </FormControl>
          <FormControl>
            <FormControl.Label>Secret PIN</FormControl.Label>
            <Input
              variant="filled"
              rounded="lg"
              size="lg"
              InputLeftElement={
                <Center p={2.5} bg="primary.100">
                  <FiLock size={20} color="red" />
                </Center>
              }
              InputRightElement={
                codeType === "password" ? (
                  <Button
                    onPress={() => {
                      setCodeType("text");
                    }}
                  >
                    <FiEyeOff color="white" />
                  </Button>
                ) : (
                  <Button
                    onPress={() => {
                      setCodeType("password");
                    }}
                  >
                    <FiEye color="white" />
                  </Button>
                )
              }
              type={codeType}
              placeholder="*****"
              value={secretCode}
              onChangeText={(value) => setSecretCode(value)}
            />
          </FormControl>

          <Box>
            <FormControl.Label>Start Date</FormControl.Label>
            <ThemeProvider theme={theme}>
              <KeyboardDatePicker
                value={dateFrom}
                onChange={setDateFrom}
                inputVariant="filled"
                format="YYYY-MM-DD"
                style={{ border: "0px solid" }}
              />
            </ThemeProvider>
          </Box>
          <Box>
            <FormControl.Label>End Date</FormControl.Label>
            <ThemeProvider theme={theme}>
              <KeyboardDatePicker
                value={dateTo}
                onChange={setDateTo}
                inputVariant="filled"
                format="YYYY-MM-DD"
                style={{ border: "0px solid" }}
              />
            </ThemeProvider>
          </Box>

          <Button isLoading={isLoading} onPress={() => validateFields()}>
            CHECK STATEMENT
          </Button>

          <Box>
            <Text textAlign="center">
              Don’t have a Secret PIN? <Link to="/register">register </Link>
            </Text>

            <Text textAlign="center" mt={1}>
              Forgot Secret PIN? <Link to="/set-pin">Reset Secret PIN</Link>
            </Text>
          </Box>
        </Stack>
        {/* form ends  */}

        {/* ----------------------------statement reports starts--------------------------- */}
        <FlatList
          bg="gray.200"
          py={4}
          w={["90%", "80%", "70%", "70%"]}
          my={5}
          rounded="lg"
          data={data}
          renderItem={({ item }) => (
            <StatementDetails
              policyN={item.policyNumber}
              amount={item.amtPaid}
              date={item.transactionDate}
              month={item.payMonth}
            />
          )}
          ListHeaderComponent={({ item }) => (
            <Center>
              {data.length ? (
                <Box my={2} w="80%">
                  <Heading fontSize="lg" mx={2} my={2} color="primary.500">
                    STATEMENT DETAILS
                  </Heading>

                  <Detail label="FULLNAME" value={data[0].fullName} />
                  <Detail label="PLAN TYPE" value={data[0].planType} />
                  <Detail label="PREMIUM" value={data[0].premium + " GHS"} />
                  <Detail label="STATUS" value={data[0].status} />
                  <Detail label="PAYMENT FREQUENCY" value={data[0].frequency} />
                  <Detail
                    label="SUM ASSURED"
                    value={data[0].sumAssured + " GHS"}
                  />
                  <Detail
                    label="EFFECTIVE DATE"
                    value={data[0].effectiveDate}
                  />
                  <Detail label="TOTAL PAID" value={data[0].total + " GHS"} />
                </Box>
              ) : (
                ""
              )}
            </Center>
          )}
          ListEmptyComponent={() => (
            <Center>
              <Text py={1}>There is nothing to show yet</Text>
            </Center>
          )}
        />
        {/* --------------------------------statement reports ends--------------------  */}
      </Center>
    </FullHeight>
  );
}

export default CheckStatementPage;

const Detail = ({ value, label }) => {
  return (
    <HStack alignItems="flex-start" justifyContent="space-between">
      <Text fontWeight="500" color="gray.600" mx={2} textAlign="left" flex={5}>
        {label}
      </Text>
      <Text fontWeight="500" color="gray.600" mx={2} textAlign="right" flex={5}>
        {value}
      </Text>
    </HStack>
  );
};

const BackgroundImage = styled.div`
  background: url("${bg}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

import { Center, Text, HStack, VStack } from "native-base";

import { FiCalendar, FiYoutube } from "react-icons/fi";
import { GrMoney } from "react-icons/gr";

import "react-toastify/dist/ReactToastify.css";

// date imports

// utils
import { months, getMonth, getYear } from "../utils/dateUtils";

export default function StatementDetails({ amount, date, month }) {
  return (
    <>
      <HStack
        bg="gray.100"
        my={3}
        mx={4}
        p={4}
        rounded="md"
        justifyContent="space-evenly"
        display={["none", "none", "flex", "flex"]}
      >
        <HStack alignItems="center" justifyContent="center" flex={3}>
          <Center bg="green.100" p={1} rounded="md">
            <GrMoney color="green" />
          </Center>
          <Text px={1} fontWeight="500">
            {amount} GHS
          </Text>
        </HStack>

        <HStack flex={3} alignItems="center">
          <Center bg="primary.200" p={1} rounded="md">
            <FiCalendar color="red" />
          </Center>
          <Text px={1} textAlign="left">
            On {date}
          </Text>
        </HStack>

        <HStack flex={3} alignItems="center">
          <Center bg="primary.200" p={1} rounded="md">
            <FiCalendar color="red" />
          </Center>
          <Text px={1} textAlign="left">
            For {months[getMonth(month)]} {getYear(month)}
          </Text>
        </HStack>
      </HStack>

      {/* small screen */}
      <VStack
        bg="gray.100"
        my={3}
        mx={4}
        p={4}
        rounded="md"
        display={["flex", "flex", "none", "none"]}
        space={3}
      >
        <HStack alignItems="center" flex={3}>
          <Center bg="green.100" p={1} rounded="md">
            <GrMoney color="green" />
          </Center>
          <Text px={1} fontWeight="500">
            {amount} GHS
          </Text>
        </HStack>

        <HStack flex={3} alignItems="center">
          <Center bg="primary.200" p={1} rounded="md">
            <FiCalendar color="red" />
          </Center>
          <Text px={1} textAlign="left">
            On {date}
          </Text>
        </HStack>

        <HStack flex={3} alignItems="center">
          <Center bg="primary.200" p={1} rounded="md">
            <FiCalendar color="red" />
          </Center>
          <Text px={1} textAlign="left">
            For {months[getMonth(month)]} {getYear(month)}
          </Text>
        </HStack>
      </VStack>
    </>
  );
}
